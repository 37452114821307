<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-footer
    class="dt-listing"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-data-table>
</template>

<script lang="ts">
import { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { Mixins, Component, Prop } from "vue-property-decorator";
import DataTableMixin from "@/mixins/DataTableMixin";
import { DataTableHeader } from "vuetify";

@Component
export default class CurrencyTable extends Mixins(DataTableMixin) {
  @Prop(Array) readonly items!: CurrencyData[];
  @Prop(Boolean) readonly withActions!: boolean;
  @Prop(Array) readonly extraHeaders!: DataTableHeader[];

  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortable: false },
    { text: "code", value: "code", sortable: false },
    { text: "symbol", value: "symbol", sortable: false },
  ];

  mounted() {
    this.$nextTick(() => {
      if (this.withActions) {
        this.addDTActionsHeader();
      }

      if (this.extraHeaders && this.extraHeaders.length) {
        this.addDTHeaders(this.extraHeaders);
      }

      this.mapDTHeaders();
    });
  }
}
</script>
